/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/scandipwa
 * @link https://github.com/scandipwa/scandipwa
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

.MyAccountLoyaltyProgram {
    &-Container {
        display: flex;
        flex-direction: column;

        h5,
        h6,
        p {
            font-family: $font-primary;
        }

        h5,
        h6 {
            font-weight: 700;
            font-style: normal;
        }
    }

    &-MainContentWrapper {
        display: flex;

        @include mobile {
            flex-direction: column;
        }
    }

    &-InfoWprapper {
        width: 305px;

        @include mobile {
            margin: 0 auto;
        }

        img {
            object-fit: contain;
        }

        p,
        span {
            font-size: 18px;
        }
    }

    &-ProgramLevelName {
        font-weight: 700;
    }

    &-StatisticsWrapper {
        margin-left: 50px;

        @include mobile {
            margin-left: 0;
            margin-top: 30px;
        }
    }


    &-Checkbox {
        margin-bottom: 20px;
    }

    &-BenefitsWrapper {
        margin-top: 60px;
    }

    &-StatisticsItemWrapper {
        display: flex;
        justify-content: flex-start;

        p {
            width: 50%;
        }
    }

    &-NotificationButton {
        @include mobile {
            width: auto;
        }
    }

    &-CustomTable {
        th {
            font-weight: 700;
        }

        td {
            font-weight: 500;
        }
    }

    &-NameTableCell {
        min-width: 250px;
        font-weight: 700 !important;
    }
}